import PublicHomePage from '../ui-components/PublicHomePage.jsx';
import MobileHomePage from '../ui-components/MobileHomePage.jsx';
import image1p from '../specific/images/ColorBanner.png';
import image2 from '../specific/images/PrimaryImage2.jpg';
import image3 from '../specific/images/PrimaryImage1.jpg';
import image4 from '../specific/images/PrimaryImage2.jpg';
import map from '../specific/images/map.png';
import pin from '../specific/images/pin.png';
import rdv from '../specific/images/LongLogoWhite.png';
import appstoreblack from '../resources/appstoreblack.svg';
import playstoreblack from '../resources/google-play-badge.png';
import settings from '../specific/settings.json'
import {APIProvider, Map, AdvancedMarker, Pin} from '@vis.gl/react-google-maps';
import { useState, useEffect } from 'react';


const PublicHome = (state) => {
    const [position, setPosition] = useState({ lat: 51.421360, lng: 0 });
    const [key, setKey] = useState(0);
    const [mapComponent, setMapComponent] = useState();
    const [locations, setLocations] = useState([
        {key: 'You', location: { lat:  position.lat, lng: position.lng }},
        {possible_one: "?", location: { lat: (position.lat+(0.03*(0.5-Math.random()))), lng: (position.lng+(0.03*(0.5-Math.random()))) }},
        {possible_two: "?", location: { lat: (position.lat+(0.03*(0.5-Math.random()))), lng: (position.lng+(0.03*(0.5-Math.random()))) }},
        {possible_three: "?", location: { lat: (position.lat+(0.03*(0.5-Math.random()))), lng: (position.lng+(0.03*(0.5-Math.random()))) }},
        {possible_four: "?", location: { lat: (position.lat+(0.03*(0.5-Math.random()))), lng: (position.lng+(0.03*(0.5-Math.random()))) }},
        {possible_five: "?", location: { lat: (position.lat+(0.03*(0.5-Math.random()))), lng: (position.lng+(0.03*(0.5-Math.random()))) }}
      ]);
    const PoiMarkers = (props) => {
        return (
            <>
            {props.pois.map((poi) => (
                <AdvancedMarker
                key={poi.key}
                position={poi.location}>
                {/* <Pin background={poi.key="You"?settings.Theme.PrimaryColor : "#ffff"} glyphColor={'#000'} borderColor={'#000'} /> */}
                <img src={pin} width={32} height={32} />
                </AdvancedMarker>
            ))}
            </>
        );
    };

    useEffect(() => {
        if ("geolocation" in navigator) {
            console.log(navigator);
            navigator.geolocation.getCurrentPosition(function (position) {
                setPosition({
                    lat: position.coords.latitude,
                    lng: position.coords.longitude,
                });
                const locs = ([
                    {key: 'You', location: { lat:  position.coords.latitude, lng: position.coords.longitude }},
                    {key: "?", location: { lat: (position.coords.latitude+(0.03*(0.5-Math.random()))), lng: (position.coords.longitude+(0.03*(0.5-Math.random()))) }},
                    {key: "?", location: { lat: (position.coords.latitude+(0.03*(0.5-Math.random()))), lng: (position.coords.longitude+(0.03*(0.5-Math.random()))) }},
                    {key: "?", location: { lat: (position.coords.latitude+(0.03*(0.5-Math.random()))), lng: (position.coords.longitude+(0.03*(0.5-Math.random()))) }},
                    {key: "?", location: { lat: (position.coords.latitude+(0.03*(0.5-Math.random()))), lng: (position.coords.longitude+(0.03*(0.5-Math.random()))) }},
                    {key: "?", location: { lat: (position.coords.latitude+(0.03*(0.5-Math.random()))), lng: (position.coords.longitude+(0.03*(0.5-Math.random()))) }}
                  ])
                  console.log(position.coords)
                  setMapComponent(
                    <Map
                        key={0}
                        mapId='6d6466716bfe5e47'
                        style={{width: '100vw', height: '100vh'}}
                        defaultCenter={{lat: position.coords.latitude , lng: position.coords.longitude}}
                        defaultZoom={13}
                        gestureHandling={'greedy'}
                        disableDefaultUI={true}
                        onCameraChanged={ (ev) => console.log('camera changed:', ev.detail.center, 'zoom:', ev.detail.zoom)}
                    >
                        <PoiMarkers pois={locs} />
                    </Map>
                    )
            },function (position) {
                setPosition({
                    lat: 51.421360,
                    lng: -0.207590,
                });
                const locs = ([
                    {key: 'You', location: { lat:  51.421360, lng: -0.207590 }},
                    {key: "?", location: { lat: (51.421360+(0.03*(0.5-Math.random()))), lng: (-0.207590+(0.03*(0.5-Math.random()))) }},
                    {key: "?", location: { lat: (51.421360+(0.03*(0.5-Math.random()))), lng: (-0.207590+(0.03*(0.5-Math.random()))) }},
                    {key: "?", location: { lat: (51.421360+(0.03*(0.5-Math.random()))), lng: (-0.207590+(0.03*(0.5-Math.random()))) }},
                    {key: "?", location: { lat: (51.421360+(0.03*(0.5-Math.random()))), lng: (-0.207590+(0.03*(0.5-Math.random()))) }},
                    {key: "?", location: { lat: (51.421360+(0.03*(0.5-Math.random()))), lng: (-0.207590+(0.03*(0.5-Math.random()))) }}
                  ])
                  console.log(position.coords)
                setMapComponent(
                    <Map
                        key={0}
                        mapId='6d6466716bfe5e47'
                        style={{width: '100vw', height: '100vh'}}
                        defaultCenter={{lat: 51.421360, lng: -0.207590}}
                        defaultZoom={13}
                        gestureHandling={'greedy'}
                        disableDefaultUI={true}
                        onCameraChanged={ (ev) => console.log('camera changed:', ev.detail.center, 'zoom:', ev.detail.zoom)}
                    >
                        <PoiMarkers pois={locs} />
                    </Map>
                    )
            }
        );
        } else {
          console.log("Geolocation is not available in your browser.");
        }
      }, []);
    
    console.log(state.state.state);
    
    return (
        <div className="Terms" overflow="auto">
        {state.state.state==="desktop" ?
        <div className="PublicHome" overflow="auto">
            
            <PublicHomePage 
                right4242719={<APIProvider apiKey={'AIzaSyA5xgE8KejRCuXqmulDDHkfX4ERZvsj0Qs'}>{mapComponent}</APIProvider>}
                width ={"100%"} 
                margin="auto" 
                widthProp={"100%"} 
                image1src={image1p} 
                image2src={image2} 
                image3src={image3} 
                image4src={map} 
                logosrc={rdv} 
                signup={"/NewMember"}
                loginurl={"/Login"} 
                homeurl={"/"} 
                termsurl={"/Terms"} 
                supporturl={"/Support"}
                playstoreurl={'/NewMember'}
                appstoreurl={'/NewMember'}
                playstoresrc={playstoreblack}
                appstoresrc={appstoreblack}
                titlestr={settings.AppName}
                tmstr={(settings.IsTrademarked ? "®": "")}
                buttoncolor = {settings.Theme.PrimaryColor}
                secondaryColor = {settings.Theme.LighterColor}
                darkButtonCOlor = {settings.Theme.DarkerColor}
                text1str = {settings.Screens.PublicHomePage.text1str}
                text2str = {settings.Screens.PublicHomePage.text2str}
                step1titlestr = {settings.Screens.PublicHomePage.step1titlestr}
                step1textstr = {settings.Screens.PublicHomePage.step1textstr}
                step2titlestr = {settings.Screens.PublicHomePage.step2titlestr}
                step2textstr = {settings.Screens.PublicHomePage.step2textstr}
                step3titlestr = {settings.Screens.PublicHomePage.step3titlestr}
                step3textstr = {settings.Screens.PublicHomePage.step3textstr}
                benefitstitlestr = {settings.Screens.PublicHomePage.benefitstitlestr}
                benefit1titlestr = {settings.Screens.PublicHomePage.benefit1titlestr}
                benefit11textstr = {settings.Screens.PublicHomePage.benefit11textstr}
                benefit12textstr = {settings.Screens.PublicHomePage.benefit12textstr}
                benefit13textstr = {settings.Screens.PublicHomePage.benefit13textstr}
                benefit2titlestr = {settings.Screens.PublicHomePage.benefit2titlestr}
                benefit21textstr = {settings.Screens.PublicHomePage.benefit21textstr}
                benefit22textstr = {settings.Screens.PublicHomePage.benefit22textstr}
                benefit3titlestr = {settings.Screens.PublicHomePage.benefit3titlestr}
                benefit31textstr = {settings.Screens.PublicHomePage.benefit31textstr}
                benefit32textstr = {settings.Screens.PublicHomePage.benefit32textstr}
                getstartedtitlestr = {settings.Screens.PublicHomePage.getstartedtitlestr}
                getstartedtextstr = {settings.Screens.PublicHomePage.getstartedtextstr}
                detailstextstr = {settings.Screens.PublicHomePage.detailstextstr}
                detailstitlestr = {settings.Screens.PublicHomePage.detailstitlestr}
                copyrighttextstr = {settings.Screens.PublicHomePage.copyrighttextstr}
                pricingsrc = {image4}
                PricingTitle={"Pricing"}
                Pricing1={"Rundezvous aims to be a cheap as possible to allow as many people to benefit from the power of social running."}
                Pricing2={"Basic Membership gets you one run a week, and is free. Sign-up now and it will be free for you forever. We also include at least a month of free full-membership when you first sign-up."}
                Pricing3={"For all-you-can-run Full Membership, the cost is £4-£5 per month ($4-$5 / €4-€5) depending on the platform you sign-up though (here in the browser via Stripe will always be the lowest cost)."}
            />
        </div>
        :
        <div className="MobileHome" overflow="auto"> 
            <MobileHomePage width ={"100%"} 
            imageFrame={<APIProvider apiKey={'AIzaSyA5xgE8KejRCuXqmulDDHkfX4ERZvsj0Qs'}>{mapComponent}</APIProvider>}
            margin="auto" 
            widthProp={"100%"} 
            image1src={image1p} 
            image2src={image2} 
            image3src={image3} 
            image4src={image4}
            logosrc={rdv} 
            signup={"/NewMember"} 
            signupurl={"/NewMember"}
            loginurl={"/Login"} 
            homeurl={"/"} 
            termsurl={"/Terms"} 
            supporturl={"/Support"}
            playstoreurl={"/NewMember"}
            appstoreurl={"/NewMember"}
            playstoresrc={playstoreblack}
            appstoresrc={appstoreblack}
            betasrc={map}
            PricingTitle={"Pricing"}
                Pricing1={"Rundezvous aims to be a cheap as possible to allow as many people to benefit from the power of social running."}
                Pricing2={"Basic Membership gets you one run a week, and is free. Sign-up now and it will be free for you forever. We also include at least a month of free full-membership when you first sign-up."}
                Pricing3={"For all-you-can-run Full Membership, the cost is £4-£5 per month ($4-$5 / €4-€5) depending on the platform you sign-up though (here in the browser via Stripe will always be the lowest cost)."}
            />
        </div>
        }
    </div>
    );
}

export default PublicHome;