import { Amplify } from 'aws-amplify';
import { Authenticator, useTheme, View, Image, Text, Heading, useAuthenticator, 
  Button, CheckboxField } from '@aws-amplify/ui-react';
import '@aws-amplify/ui-react/styles.css';
import RDVLogo from './specific/images/Logo.png';
import RunList from './screens/RunList.js'; 
import MyDetails from './screens/MyDetails.js';
import EventDetailsScreen from './screens/EventDetailsScreen.js';
import PublicHome from './screens/PublicHome.js';
import PublicEvent from './screens/PublicEvent.js';
// import ClubLanding from './screens/ClubLanding.js';
import TermsPage from './screens/TermsPage.js';
import NewMember from './screens/NewMember.js';
import ThankYou from './screens/ThankYou.js';
import Subscribe from './screens/Subscribe.js';
import Schedule from './screens/Schedule.js';
import SafeguardingScreen from './screens/SafeguardingScreen.js';
import SupportPage from './screens/SupportPage.js';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import { RequireAuth } from './RequireAuth';
import './App.css'
import settings from './specific/settings.json'
import awsExports from './amplifyconfiguration.json';
import {useState, useEffect} from 'react';
import TagManager from 'react-gtm-module'

Amplify.configure(awsExports);
// const tagManagerArgs = {
//   gtmId: 'G-FN0648YCZ6'
// }
// TagManager.initialize(tagManagerArgs)

const components = {
  Header() {
    const { tokens } = useTheme();

    return (
      <View textAlign="center" padding={tokens.space.large}>
        <Image
          alt="RV Logo"
          src={RDVLogo}
          height="50%"
          width="50%"
        />
      </View>
    );
  },

  Footer() {
    const { tokens } = useTheme();

    return (
      <View textAlign="center" padding={tokens.space.large}>
        <Text color={tokens.colors.neutral[80]}>
          &copy; All Rights Reserved
        </Text>
      </View>
    );
  },

  SignIn: {
    Header() {
      const { tokens } = useTheme();

      return (
        <Heading
          padding={`${tokens.space.xl} 0 0 ${tokens.space.xl}`}
          level={3}
        >
          Sign in to your Rundezvous Account
        </Heading>
      );
    },
    Footer() {
      const { toResetPassword } = useAuthenticator();

      return (
        <View textAlign="center">
          <View>
            <Button 
              fontWeight="normal"
              onClick={() => {window.location.href="/NewMember"}}
              size="small"
              width={"87%"}
              
              variation="button">Create account</Button>
              
          </View>
          <Button
            fontWeight="normal"
            onClick={toResetPassword}
            size="small"
            variation="link"
            marginBottom={20}
            marginTop={20}
          >
            Reset Password
          </Button>
        </View>
      );
    },
  },

  // SignUp: {
  //   FormFields() {
  //     const { validationErrors } = useAuthenticator();

  //     return (
  //       <>
  //         {/* Re-use default `Authenticator.SignUp.FormFields` */}
  //         <Authenticator.SignUp.FormFields />

  //         {/* Append & require Terms & Conditions field to sign up  */}
  //         <a href={settings.Domain+"Terms"} target="_blank" rel="noreferrer">Terms & Conditions</a>
  //         <CheckboxField
  //           errorMessage={validationErrors.acknowledgement}
  //           hasError={!!validationErrors.acknowledgement}
  //           name="acknowledgement"
  //           value="yes"
  //           label="I agree with the Terms & Conditions"
  //         />
  //       </>
  //     );
  //   },
  //   Header() {
  //     const { tokens } = useTheme();

  //     return (
  //       <Heading
  //         padding={`${tokens.space.xl} 0 0 ${tokens.space.xl}`}
  //         level={3}
  //       >
  //         Create a new scheduling account
  //       </Heading>
  //     );
  //   },
  //   Footer() {
  //     const { toSignIn } = useAuthenticator();

  //     return (
  //       <View textAlign="center">
  //         <Button
  //           fontWeight="normal"
  //           onClick={toSignIn}
  //           size="small"
  //           variation="link"
  //         >
  //           Back to Sign In
  //         </Button>
  //       </View>
  //     );
  //   },
  // },
  // ConfirmSignUp: {
  //   Header() {
  //     const { tokens } = useTheme();
  //     return (
  //       <Heading
  //         padding={`${tokens.space.xl} 0 0 ${tokens.space.xl}`}
  //         level={3}
  //       >
  //         Enter Information:
  //       </Heading>
  //     );
  //   },
  //   Footer() {
  //     return <Text>Footer Information</Text>;
  //   },
  // },
  SetupTOTP: {
    Header() {
      const { tokens } = useTheme();
      return (
        <Heading
          padding={`${tokens.space.xl} 0 0 ${tokens.space.xl}`}
          level={3}
        >
          Enter Information:
        </Heading>
      );
    },
    Footer() {
      return <Text>Footer Information</Text>;
    },
  },
  ConfirmSignIn: {
    Header() {
      const { tokens } = useTheme();
      return (
        <Heading
          padding={`${tokens.space.xl} 0 0 ${tokens.space.xl}`}
          level={3}
        >
          Enter Information:
        </Heading>
      );
    },
    Footer() {
      return <Text>Footer Information</Text>;
    },
  },
  ResetPassword: {
    Header() {
      const { tokens } = useTheme();
      return (
        <Heading
          padding={`${tokens.space.xl} 0 0 ${tokens.space.xl}`}
          level={3}
        >
          Enter Information:
        </Heading>
      );
    },
    Footer() {
      return <Text>Footer Information</Text>;
    },
  },
  ConfirmResetPassword: {
    Header() {
      const { tokens } = useTheme();
      return (
        <Heading
          padding={`${tokens.space.xl} 0 0 ${tokens.space.xl}`}
          level={3}
        >
          Enter Information:
        </Heading>
      );
    },
    Footer() {
      return <Text>Footer Information</Text>;
    },
  },
};

const formFields = {
  signIn: {
    username: {
      placeholder: 'Enter your email',
      order: 1,
    },
  },
  forceNewPassword: {
    password: {
      placeholder: 'Enter your Password:',
    },
  },
  resetPassword: {
    username: {
      placeholder: 'Enter your email:',
    },
  },
  confirmResetPassword: {
    confirmation_code: {
      placeholder: 'Enter your Confirmation Code:',
      label: 'New Label',
      isRequired: false,
    },
    confirm_password: {
      placeholder: 'Enter your Password Please:',
    },
  },
  setupTOTP: {
    QR: {
      totpIssuer: 'test issuer',
      totpUsername: 'amplify_qr_test_user',
    },
    confirmation_code: {
      label: 'New Label',
      placeholder: 'Enter your Confirmation Code:',
      isRequired: false,
    },
  },
  confirmSignIn: {
    confirmation_code: {
      label: 'New Label',
      placeholder: 'Enter your Confirmation Code:',
      isRequired: false,
    },
  },
};

const services={
  async validateCustomSignUp(formData) {
    if (!formData.acknowledgement) {
      return {
        acknowledgement: 'You must agree to the Terms & Conditions',
      };
    }
  },
};

function MyRoutes(state) {
  return (
    <BrowserRouter>
      <Routes>
          <Route
            path="/RunList"
            element={
              <Authenticator formFields={formFields} components={components} services={services} hideSignUp>
              <RequireAuth>
                <RunList state={state}/>
              </RequireAuth>
              </Authenticator>
            }
          />
          <Route
            path="/ThankYou"
            element={
              <Authenticator formFields={formFields} components={components} services={services} hideSignUp>
              <RequireAuth>
                <ThankYou state={state}/>
              </RequireAuth>
              </Authenticator>
            }
          />
          <Route
            path="/MyDetails"
            element={
              <Authenticator formFields={formFields} components={components} services={services} hideSignUp>
              <RequireAuth>
                <MyDetails state={state}/>
              </RequireAuth>
              </Authenticator>
            }
          />
          <Route
            path="/Schedule"
            element={
              <Authenticator formFields={formFields} components={components} services={services} hideSignUp>
              <RequireAuth>
                <Schedule state={state}/>
              </RequireAuth>
              </Authenticator>
            }
          />
          <Route
            path="/NewMember"
            element={
              <NewMember state={state}/>
            }
          />
          <Route
            path="/EventDetails/:id"
            element={
              <Authenticator formFields={formFields} components={components} services={services} hideSignUp>
              <RequireAuth>
                <EventDetailsScreen state={state} />
              </RequireAuth>
              </Authenticator>
            }
          />
          <Route
            path="/Subscribe"
            element={
              <Authenticator formFields={formFields} components={components} services={services} hideSignUp>
              <RequireAuth>
                <Subscribe state={state} />
              </RequireAuth>
              </Authenticator>
            }
          />
          <Route
            path="/HomePage"
            element={
              <Authenticator formFields={formFields} components={components} services={services} hideSignUp>
              <RequireAuth>
                <RunList state={state}/>
              </RequireAuth>
              </Authenticator>
            }
          />
          <Route
            path="/PublicHome"
            element={
              <PublicHome state={state}/>
            }
          />
          <Route
            path="/PublicEvent/:id"
            element={
              <PublicEvent state={state}/>
            }
          />
          <Route
            path="/Terms"
            element={
              <TermsPage state={state}/>
            }
          />
          <Route
            path="/Support"
            element={
              <SupportPage state={state}/>
            }
          />
          <Route
            path="/Safeguarding"
            element={
              <SafeguardingScreen state={state}/>
            }
          />
          <Route path="/referral/:code" element={
              <NewMember state={state}/>
            }
          />
          <Route path="/login" element={
            <Authenticator formFields={formFields} components={components} services={services} hideSignUp>
              <RunList state={state} />
            </Authenticator>
            } 
          />
          <Route path="" element={
              <PublicHome state={state} />
            }>
        </Route>
      </Routes>
    </BrowserRouter>
  );
}

function App() {
  const [width, setWidth] = useState(window.innerWidth);

  function handleWindowSizeChange() {
      setWidth(window.innerWidth);
  }
  useEffect(() => {
      window.addEventListener('resize', handleWindowSizeChange);
      return () => {
          window.removeEventListener('resize', handleWindowSizeChange);
      }
  }, []);

  const isMobile = width <= 1200;

  return (
    <div className="App">
      <div className={`container`}>
          <MyRoutes state={isMobile? 'phone' : 'desktop'}/>
      </div>
    </div>
  );
}

export default App; 