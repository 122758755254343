/***************************************************************************
 * The contents of this file were generated with Amplify Studio.           *
 * Please refrain from making any modifications to this file.              *
 * Any changes to this file will be overwritten when running amplify pull. *
 **************************************************************************/

/* eslint-disable */
import * as React from "react";
import { getOverrideProps } from "./utils";
import { Button, Flex, Text } from "@aws-amplify/ui-react";
export default function ReferralPage(props) {
  const {
    tokens = "1",
    pending = "1",
    completed = "1",
    code = "FKLSEFSFD",
    copyEvent,
    shareEvent,
    frame512,
    overrides,
    ...rest
  } = props;
  return (
    <Flex
      gap="2px"
      direction="column"
      width="262px"
      height="unset"
      justifyContent="flex-start"
      alignItems="flex-start"
      overflow="hidden"
      position="relative"
      padding="10px 10px 10px 10px"
      backgroundColor="rgba(255,255,255,1)"
      {...getOverrideProps(overrides, "ReferralPage")}
      {...rest}
    >
      <Flex
        gap="1px"
        direction="column"
        width="unset"
        height="unset"
        justifyContent="flex-start"
        alignItems="flex-start"
        overflow="hidden"
        shrink="0"
        alignSelf="stretch"
        position="relative"
        padding="10px 0px 10px 0px"
        {...getOverrideProps(overrides, "ReferralArea43441305")}
      >
        <Flex
          gap="10px"
          direction="row"
          width="unset"
          height="unset"
          justifyContent="center"
          alignItems="center"
          shrink="0"
          alignSelf="stretch"
          position="relative"
          padding="0px 10px 0px 10px"
          {...getOverrideProps(overrides, "Frame 50643441306")}
        >
          <Text
            //fontFamily="Inter"
            fontSize="12px"
            fontWeight="400"
            color="rgba(151,71,255,1)"
            lineHeight="24px"
            textAlign="left"
            display="block"
            direction="column"
            justifyContent="unset"
            letterSpacing="0px"
            width="unset"
            height="unset"
            gap="unset"
            alignItems="unset"
            shrink="0"
            position="relative"
            padding="0px 0px 0px 0px"
            whiteSpace="pre-wrap"
            children="Referral Code"
            {...getOverrideProps(overrides, "Referral Code")}
          ></Text>
        </Flex>
        <Flex
          gap="0"
          direction="row"
          width="unset"
          height="unset"
          justifyContent="center"
          alignItems="center"
          overflow="hidden"
          shrink="0"
          alignSelf="stretch"
          position="relative"
          padding="10px 0px 10px 0px"
          {...getOverrideProps(overrides, "Frame 51143441308")}
        >
          <Flex
            gap="10px"
            direction="row"
            width="unset"
            height="30px"
            justifyContent="flex-start"
            alignItems="center"
            overflow="hidden"
            shrink="0"
            position="relative"
            padding="0px 10px 0px 10px"
            {...getOverrideProps(overrides, "Frame 45843441309")}
          >
            <Text
              //fontFamily="Inter"
              fontSize="30px"
              fontWeight="400"
              color="rgba(0,0,0,1)"
              lineHeight="20px"
              textAlign="left"
              display="block"
              direction="column"
              justifyContent="unset"
              letterSpacing="0.15px"
              width="unset"
              height="unset"
              gap="unset"
              alignItems="unset"
              shrink="0"
              position="relative"
              padding="0px 0px 0px 0px"
              whiteSpace="pre-wrap"
              children={code}
              onClick={shareEvent}
              {...getOverrideProps(overrides, "GVFKCHD115")}
            ></Text>
          </Flex>
        </Flex>
        <Flex
          gap="10px"
          direction="column"
          width="unset"
          height="unset"
          justifyContent="center"
          alignItems="center"
          shrink="0"
          alignSelf="stretch"
          position="relative"
          padding="10px 10px 10px 10px"
          {...getOverrideProps(overrides, "Frame 507")}
        >
          <Button
            width="unset"
            height="40px"
            shrink="0"
            alignSelf="stretch"
            size="default"
            isDisabled={false}
            variation="primary"
            children="Copy Code"
            onClick={copyEvent}
            {...getOverrideProps(overrides, "ShareReferralCodeButton")}
          ></Button>
        </Flex>
        <Flex
          gap="10px"
          direction="column"
          width="unset"
          height="unset"
          justifyContent="center"
          alignItems="center"
          shrink="0"
          alignSelf="stretch"
          position="relative"
          padding="10px 10px 10px 10px"
          children={frame512}
          {...getOverrideProps(overrides, "Frame 512")}
        ></Flex>
      </Flex>
      <Flex
        gap="1px"
        direction="column"
        width="unset"
        height="unset"
        justifyContent="flex-start"
        alignItems="flex-start"
        overflow="hidden"
        shrink="0"
        alignSelf="stretch"
        position="relative"
        padding="10px 0px 10px 0px"
        {...getOverrideProps(overrides, "SubscriptionArea")}
      >
        <Flex
          gap="10px"
          direction="row"
          width="unset"
          height="unset"
          justifyContent="center"
          alignItems="center"
          shrink="0"
          alignSelf="stretch"
          position="relative"
          padding="0px 10px 0px 10px"
          {...getOverrideProps(overrides, "Frame 503")}
        >
          <Text
            //fontFamily="Inter"
            fontSize="12px"
            fontWeight="400"
            color="rgba(151,71,255,1)"
            lineHeight="24px"
            textAlign="left"
            display="block"
            direction="column"
            justifyContent="unset"
            letterSpacing="0px"
            width="unset"
            height="unset"
            gap="unset"
            alignItems="unset"
            shrink="0"
            position="relative"
            padding="0px 0px 0px 0px"
            whiteSpace="pre-wrap"
            children="Pending Referrals"
            {...getOverrideProps(overrides, "Pending Referrals")}
          ></Text>
        </Flex>
        <Flex
          gap="0"
          direction="row"
          width="unset"
          height="unset"
          justifyContent="center"
          alignItems="center"
          overflow="hidden"
          shrink="0"
          alignSelf="stretch"
          position="relative"
          padding="10px 0px 10px 0px"
          {...getOverrideProps(overrides, "Frame 457")}
        >
          <Flex
            gap="10px"
            direction="row"
            width="unset"
            height="unset"
            justifyContent="flex-start"
            alignItems="center"
            overflow="hidden"
            shrink="0"
            alignSelf="stretch"
            position="relative"
            padding="0px 10px 0px 10px"
            {...getOverrideProps(overrides, "Frame 45843441258")}
          >
            <Text
              //fontFamily="Inter"
              fontSize="30px"
              fontWeight="400"
              color="rgba(0,0,0,1)"
              lineHeight="20px"
              textAlign="left"
              display="block"
              direction="column"
              justifyContent="unset"
              letterSpacing="0.15px"
              width="14px"
              height="30px"
              gap="unset"
              alignItems="unset"
              shrink="0"
              position="relative"
              padding="0px 0px 0px 0px"
              whiteSpace="pre-wrap"
              children={pending}
              {...getOverrideProps(overrides, "1")}
            ></Text>
          </Flex>
        </Flex>
      </Flex>
      <Flex
        gap="1px"
        direction="column"
        width="unset"
        height="unset"
        justifyContent="flex-start"
        alignItems="flex-start"
        overflow="hidden"
        shrink="0"
        alignSelf="stretch"
        position="relative"
        padding="10px 0px 10px 0px"
        {...getOverrideProps(overrides, "ReferralArea43441263")}
      >
        <Flex
          gap="10px"
          direction="row"
          width="unset"
          height="unset"
          justifyContent="center"
          alignItems="center"
          shrink="0"
          alignSelf="stretch"
          position="relative"
          padding="0px 10px 0px 10px"
          {...getOverrideProps(overrides, "Frame 50643441264")}
        >
          <Text
            //fontFamily="Inter"
            fontSize="12px"
            fontWeight="400"
            color="rgba(151,71,255,1)"
            lineHeight="24px"
            textAlign="left"
            display="block"
            direction="column"
            justifyContent="unset"
            letterSpacing="0px"
            width="unset"
            height="unset"
            gap="unset"
            alignItems="unset"
            shrink="0"
            position="relative"
            padding="0px 0px 0px 0px"
            whiteSpace="pre-wrap"
            children="Completed Referals"
            {...getOverrideProps(overrides, "Completed Referals")}
          ></Text>
        </Flex>
        <Flex
          gap="0"
          direction="row"
          width="unset"
          height="unset"
          justifyContent="center"
          alignItems="center"
          overflow="hidden"
          shrink="0"
          alignSelf="stretch"
          position="relative"
          padding="10px 0px 10px 0px"
          {...getOverrideProps(overrides, "Frame 51143441289")}
        >
          <Flex
            gap="10px"
            direction="row"
            width="unset"
            height="unset"
            justifyContent="flex-start"
            alignItems="center"
            overflow="hidden"
            shrink="0"
            alignSelf="stretch"
            position="relative"
            padding="0px 10px 0px 10px"
            {...getOverrideProps(overrides, "Frame 45843441290")}
          >
            <Text
              //fontFamily="Inter"
              fontSize="30px"
              fontWeight="400"
              color="rgba(0,0,0,1)"
              lineHeight="20px"
              textAlign="left"
              display="block"
              direction="column"
              justifyContent="unset"
              letterSpacing="0.15px"
              width="14px"
              height="30px"
              gap="unset"
              alignItems="unset"
              shrink="0"
              position="relative"
              padding="0px 0px 0px 0px"
              whiteSpace="pre-wrap"
              children={completed}
              {...getOverrideProps(overrides, "2")}
            ></Text>
          </Flex>
        </Flex>
      </Flex>
    </Flex>
  );
}
