/***************************************************************************
 * The contents of this file were generated with Amplify Studio.           *
 * Please refrain from making any modifications to this file.              *
 * Any changes to this file will be overwritten when running amplify pull. *
 **************************************************************************/

/* eslint-disable */
import * as React from "react";
import { getOverrideProps } from "./utils";
import { Flex, Icon } from "@aws-amplify/ui-react";
export default function MobileProgressBar(props) {
  const {
    oneactive = true,
    onetocome = true,
    onecomplete = true,
    twoactive = true,
    twotocome = true,
    twocomplete = true,
    threeactive = true,
    threetocome = true,
    threecomplete = true,
    fouractive = true,
    fourtocome = true,
    fourcomplete = true,
    overrides,
    ...rest
  } = props;
  return (
    <Flex
      gap="0"
      direction="row"
      width="500px"
      height="78px"
      justifyContent="center"
      alignItems="flex-start"
      position="relative"
      padding="0px 0px 0px 0px"
      backgroundColor="rgba(250,250,250,1)"
      {...getOverrideProps(overrides, "MobileProgressBar")}
      {...rest}
    >
      <Flex
        gap="0"
        direction="row"
        width="unset"
        height="unset"
        justifyContent="center"
        alignItems="flex-start"
        overflow="hidden"
        grow="1"
        shrink="1"
        basis="0"
        position="relative"
        border="1px SOLID rgba(0,0,0,0.2)"
        boxShadow="0px 2px 4px rgba(0, 0, 0, 0.05000000074505806)"
        padding="0px 0px 0px 0px"
        backgroundColor="rgba(255,255,255,1)"
        {...getOverrideProps(overrides, "NavBar")}
      >
        <Flex
          gap="0"
          direction="row"
          width="unset"
          height="70px"
          justifyContent="center"
          alignItems="center"
          overflow="hidden"
          grow="1"
          shrink="1"
          basis="0"
          position="relative"
          padding="0px 0px 0px 0px"
          {...getOverrideProps(overrides, "Frame 44545321488")}
        >
          <Flex
            gap="0"
            direction="row"
            width="unset"
            height="unset"
            justifyContent="flex-start"
            alignItems="flex-start"
            shrink="0"
            position="relative"
            padding="0px 0px 0px 0px"
            {...getOverrideProps(overrides, "Frame 60245321491")}
          >
            <Icon
              width={onecomplete == true ? "40" : "0"}
              height="40px"
              viewBox={{ minX: 0, minY: 0, width: 40, height: 40 }}
              paths={[
                {
                  d: "M20 0C8.96 0 0 8.96 0 20C0 31.04 8.96 40 20 40C31.04 40 40 31.04 40 20C40 8.96 31.04 0 20 0ZM20 36C11.18 36 4 28.82 4 20C4 11.18 11.18 4 20 4C28.82 4 36 11.18 36 20C36 28.82 28.82 36 20 36ZM29.18 11.16L16 24.34L10.82 19.18L8 22L16 30L32 14L29.18 11.16Z",
                  fill: "rgba(151,71,255,1)",
                  fillRule: "nonzero",
                },
              ]}
              display="block"
              gap="unset"
              alignItems="unset"
              justifyContent="unset"
              shrink="0"
              position="relative"
              {...getOverrideProps(overrides, "Vector45321492")}
            ></Icon>
            <Icon
              width={onetocome == true ? "40" : "0"}
              height="40px"
              viewBox={{ minX: 0, minY: 0, width: 40, height: 40 }}
              paths={[
                {
                  d: "M20 0C8.96 0 0 8.96 0 20C0 31.04 8.96 40 20 40C31.04 40 40 31.04 40 20C40 8.96 31.04 0 20 0ZM20 36C11.18 36 4 28.82 4 20C4 11.18 11.18 4 20 4C28.82 4 36 11.18 36 20C36 28.82 28.82 36 20 36Z",
                  fill: "rgba(151,71,255,1)",
                  fillRule: "nonzero",
                },
              ]}
              display="block"
              gap="unset"
              alignItems="unset"
              justifyContent="unset"
              shrink="0"
              position="relative"
              {...getOverrideProps(overrides, "Vector45321493")}
            ></Icon>
            <Icon
              width={oneactive == true ? "40" : "0"}
              height="40px"
              viewBox={{ minX: 0, minY: 0, width: 40, height: 40 }}
              paths={[
                {
                  d: "M20 0C8.96 0 0 8.96 0 20C0 31.04 8.96 40 20 40C31.04 40 40 31.04 40 20C40 8.96 31.04 0 20 0ZM20.5 13C11.68 13 20.5 28.32 20.5 19.5C11.68 19.5 20.5 10.68 20.5 19.5C20.5 10.68 29.32 19.5 20.5 19.5C20.5 28.32 29.32 13 20.5 13Z",
                  fill: "rgba(151,71,255,1)",
                  fillRule: "nonzero",
                },
              ]}
              display="block"
              gap="unset"
              alignItems="unset"
              justifyContent="unset"
              shrink="0"
              position="relative"
              {...getOverrideProps(overrides, "Vector45321494")}
            ></Icon>
          </Flex>
        </Flex>
        <Flex
          gap="0"
          direction="row"
          width="unset"
          height="70px"
          justifyContent="center"
          alignItems="center"
          overflow="hidden"
          grow="1"
          shrink="1"
          basis="0"
          position="relative"
          padding="0px 0px 0px 0px"
          {...getOverrideProps(overrides, "Frame 44545321482")}
        >
          <Flex
            gap="0"
            direction="row"
            width="unset"
            height="unset"
            justifyContent="flex-start"
            alignItems="flex-start"
            shrink="0"
            position="relative"
            padding="0px 0px 0px 0px"
            {...getOverrideProps(overrides, "Frame 60245321495")}
          >
            <Icon
              width={twocomplete == true ? "40" : "0"}
              height="40px"
              viewBox={{ minX: 0, minY: 0, width: 40, height: 40 }}
              paths={[
                {
                  d: "M20 0C8.96 0 0 8.96 0 20C0 31.04 8.96 40 20 40C31.04 40 40 31.04 40 20C40 8.96 31.04 0 20 0ZM20 36C11.18 36 4 28.82 4 20C4 11.18 11.18 4 20 4C28.82 4 36 11.18 36 20C36 28.82 28.82 36 20 36ZM29.18 11.16L16 24.34L10.82 19.18L8 22L16 30L32 14L29.18 11.16Z",
                  fill: "rgba(151,71,255,1)",
                  fillRule: "nonzero",
                },
              ]}
              display="block"
              gap="unset"
              alignItems="unset"
              justifyContent="unset"
              shrink="0"
              position="relative"
              {...getOverrideProps(overrides, "Vector45321496")}
            ></Icon>
            <Icon
              width={twotocome == true ? "40" : "0"}
              height="40px"
              viewBox={{ minX: 0, minY: 0, width: 40, height: 40 }}
              paths={[
                {
                  d: "M20 0C8.96 0 0 8.96 0 20C0 31.04 8.96 40 20 40C31.04 40 40 31.04 40 20C40 8.96 31.04 0 20 0ZM20 36C11.18 36 4 28.82 4 20C4 11.18 11.18 4 20 4C28.82 4 36 11.18 36 20C36 28.82 28.82 36 20 36Z",
                  fill: "rgba(151,71,255,1)",
                  fillRule: "nonzero",
                },
              ]}
              display="block"
              gap="unset"
              alignItems="unset"
              justifyContent="unset"
              shrink="0"
              position="relative"
              {...getOverrideProps(overrides, "Vector45321497")}
            ></Icon>
            <Icon
              width={twoactive == true ? "40" : "0"}
              height="40px"
              viewBox={{ minX: 0, minY: 0, width: 40, height: 40 }}
              paths={[
                {
                  d: "M20 0C8.96 0 0 8.96 0 20C0 31.04 8.96 40 20 40C31.04 40 40 31.04 40 20C40 8.96 31.04 0 20 0ZM20.5 13C11.68 13 20.5 28.32 20.5 19.5C11.68 19.5 20.5 10.68 20.5 19.5C20.5 10.68 29.32 19.5 20.5 19.5C20.5 28.32 29.32 13 20.5 13Z",
                  fill: "rgba(151,71,255,1)",
                  fillRule: "nonzero",
                },
              ]}
              display="block"
              gap="unset"
              alignItems="unset"
              justifyContent="unset"
              shrink="0"
              position="relative"
              {...getOverrideProps(overrides, "Vector45321498")}
            ></Icon>
          </Flex>
        </Flex>
        <Flex
          gap="0"
          direction="row"
          width="unset"
          height="70px"
          justifyContent="center"
          alignItems="center"
          overflow="hidden"
          grow="1"
          shrink="1"
          basis="0"
          position="relative"
          padding="0px 0px 0px 0px"
          {...getOverrideProps(overrides, "Frame 446")}
        >
          <Flex
            gap="0"
            direction="row"
            width="unset"
            height="unset"
            justifyContent="flex-start"
            alignItems="flex-start"
            shrink="0"
            position="relative"
            padding="0px 0px 0px 0px"
            {...getOverrideProps(overrides, "Frame 60245321509")}
          >
            <Icon
              width={fourcomplete == true ? "40" : "0"}
              height="40px"
              viewBox={{ minX: 0, minY: 0, width: 40, height: 40 }}
              paths={[
                {
                  d: "M20 0C8.96 0 0 8.96 0 20C0 31.04 8.96 40 20 40C31.04 40 40 31.04 40 20C40 8.96 31.04 0 20 0ZM20 36C11.18 36 4 28.82 4 20C4 11.18 11.18 4 20 4C28.82 4 36 11.18 36 20C36 28.82 28.82 36 20 36ZM29.18 11.16L16 24.34L10.82 19.18L8 22L16 30L32 14L29.18 11.16Z",
                  fill: "rgba(151,71,255,1)",
                  fillRule: "nonzero",
                },
              ]}
              display="block"
              gap="unset"
              alignItems="unset"
              justifyContent="unset"
              shrink="0"
              position="relative"
              {...getOverrideProps(overrides, "Vector45321510")}
            ></Icon>
            <Icon
              width={fourtocome == true ? "40" : "0"}
              height="40px"
              viewBox={{ minX: 0, minY: 0, width: 40, height: 40 }}
              paths={[
                {
                  d: "M20 0C8.96 0 0 8.96 0 20C0 31.04 8.96 40 20 40C31.04 40 40 31.04 40 20C40 8.96 31.04 0 20 0ZM20 36C11.18 36 4 28.82 4 20C4 11.18 11.18 4 20 4C28.82 4 36 11.18 36 20C36 28.82 28.82 36 20 36Z",
                  fill: "rgba(151,71,255,1)",
                  fillRule: "nonzero",
                },
              ]}
              display="block"
              gap="unset"
              alignItems="unset"
              justifyContent="unset"
              shrink="0"
              position="relative"
              {...getOverrideProps(overrides, "Vector45321511")}
            ></Icon>
            <Icon
              width={fouractive == true ? "40" : "0"}
              height="40px"
              viewBox={{ minX: 0, minY: 0, width: 40, height: 40 }}
              paths={[
                {
                  d: "M20 0C8.96 0 0 8.96 0 20C0 31.04 8.96 40 20 40C31.04 40 40 31.04 40 20C40 8.96 31.04 0 20 0ZM20.5 13C11.68 13 20.5 28.32 20.5 19.5C11.68 19.5 20.5 10.68 20.5 19.5C20.5 10.68 29.32 19.5 20.5 19.5C20.5 28.32 29.32 13 20.5 13Z",
                  fill: "rgba(151,71,255,1)",
                  fillRule: "nonzero",
                },
              ]}
              display="block"
              gap="unset"
              alignItems="unset"
              justifyContent="unset"
              shrink="0"
              position="relative"
              {...getOverrideProps(overrides, "Vector45321512")}
            ></Icon>
          </Flex>
        </Flex>
      </Flex>
    </Flex>
  );
}
