import Navbar from '../components/Navbar.js';
import { Flex, useTheme } from '@aws-amplify/ui-react';
import { getCurrentUser } from 'aws-amplify/auth';
// import useMyFetch from '../utils/useMyFetch.js'
import getSchedule from '../utils/getSchedule.js';
import getMember from '../utils/getMember.js';
import getAttendance from '../utils/getAttendance.js';
import DesktopLayout from '../ui-components/DesktopLayout.jsx';
import MyDetailsHeader from '../ui-components/MyDetailsHeader.jsx';
import FormHolder from '../ui-components/FormHolder.jsx';
import ScheduleItem from '../ui-components/ScheduleItem.jsx';
import EditScheduleWrapper from '../ui-components/EditScheduleWrapper.jsx';
import { useState, useEffect } from 'react';
// import updateScheduleAPI from '../utils/updateScheduleAPI.js'
import updateSchedule from '../utils/updateSchedule.js'
import MobileLayout from '../ui-components/MobileLayout.jsx';
import MobileSideBar from '../ui-components/MobileSideBar.jsx';
import MobileHeaderBar from '../ui-components/MobileHeaderBar.jsx';
import RDVLogo from '../specific/images/Logo.png';
import logo from '../specific/images/LongLogoColor.png';
import MenuIcon from '../resources/menu.png';
import LogoutIcon from '../resources/logout.png';
import { Button } from "@aws-amplify/ui-react";
import settings from '../specific/settings.json'
import { list } from 'aws-amplify/storage';
import { StorageImage } from '@aws-amplify/ui-react-storage';
import { useNavigate } from 'react-router';

// [1 1 0 1 1 1 0 0 0 0 0 0 1 0 1 1 1 1 1 1 1 1 1 1 0 1 1 1 1 1 0 1 0 1 0 1 1 0 1 1 1 1 1 1 1 1 1 1 1 1 1 1 1 1 1 0 0 1 1 1 0 1 0 0 1 0 0 0 0 0 1 1 1 1 1 0 1 0 0 0 0 0 0 1 0 0 0 0 1 0 0 0 0 0 1 0 1 1]

async function currentAuthenticatedUser() {
    try {
      const { username, userId, signInDetails } = await getCurrentUser();
      return signInDetails.loginId;
    } catch (err) {
      console.log(err);
    }
  }

function createScheduleArray(str) {
    const days = ["Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday", "Sunday"];
    const bhs = ["06:00", "07:00", "08:00", "09:00", "10:00", "11:00", "12:00",
        "13:00", "14:00", "15:00", "16:00", "17:00", "18:00", "19:00"];
    const ehs = ["07:00", "08:00", "09:00", "10:00", "11:00", "12:00", "13:00",
        "14:00", "15:00", "16:00", "17:00", "18:00", "19:00", "20:00"];
    var retArr = [];
    str = str.slice(1, -1).replaceAll(" ", "");
    var active;
    var carry;
    var overIndex = 0;
    for (var i = 0; i < 7; i++) {
        active = false;
        carry = 0;
        for (var j = 0; j < 14; j++) {
            if (str.charAt((i * 14) + j) === "0") {
                if (active) {
                    active = false;
                    retArr.push({
                        day: days[i],
                        bh: bhs[j - carry],
                        eh: ehs[j - 1],
                        bhindex: j - carry,
                        ehindex: j - 1,
                        index: overIndex
                    })
                    overIndex++;
                    carry = 0;
                } else {
                    active = false;
                }
            } else {
                if (active) {
                    carry++;
                } else {
                    active = true;
                    carry++;
                }
            }
        }
        if (active) {
            retArr.push({
                day: days[i],
                bh: bhs[14 - carry],
                eh: ehs[13],
                bhindex: 14 - carry,
                ehindex: 13,
                index: overIndex
            })
            overIndex++;
        }
    }
    return retArr;
}

function convertBackSchedule(arr) {
    const days = ["Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday", "Sunday"];
    const bhs = ["06:00", "07:00", "08:00", "09:00", "10:00", "11:00", "12:00",
        "13:00", "14:00", "15:00", "16:00", "17:00", "18:00", "19:00"];
    const ehs = ["07:00", "08:00", "09:00", "10:00", "11:00", "12:00", "13:00",
        "14:00", "15:00", "16:00", "17:00", "18:00", "19:00", "20:00"];
    var str = "00000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000"
    var strArr = str.split("");
    for (var i = 0; i < arr.length; i++) {
        var dayIndex = days.indexOf(arr[i].day);
        var bhIndex = bhs.indexOf(arr[i].bh);
        var ehIndex = ehs.indexOf(arr[i].eh);
        for (var j = bhIndex; j <= ehIndex; j++) {
            strArr[(dayIndex * 14) + j] = "1";
        }
    }
    str = "[ " + strArr.join(" ") + " ]";
    return str;
}

function Schedule(state) {
    const navigate = useNavigate();
    const days = ["Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday", "Sunday"];
    const bhs = ["06:00", "07:00", "08:00", "09:00", "10:00", "11:00", "12:00",
        "13:00", "14:00", "15:00", "16:00", "17:00", "18:00", "19:00"];
    const ehs = ["07:00", "08:00", "09:00", "10:00", "11:00", "12:00", "13:00",
        "14:00", "15:00", "16:00", "17:00", "18:00", "19:00", "20:00"];
    const [menu, setMenu] = useState(false);
    // const { data: member } = useMyFetch('member', false);
    const [member, setMember] = useState({});
    const [schedule, setSchedule] = useState([]);
    const [scheduleString, setScheduleString] = useState(null);
    const [slot1Show, setSlot1Show] = useState(true);
    const [bh1, setBh1] = useState(null);
    const [eh1, setEh1] = useState(null);
    const [bh1Opt, setBh1Opt] = useState(bhs);
    const [eh1Opt, setEh1Opt] = useState(ehs);
    const [slot2Show, setSlot2Show] = useState(false);
    const [draft1, setDraft1] = useState(true);
    const [bh2, setBh2] = useState(null);
    const [eh2, setEh2] = useState(null);
    const [bh2Opt, setBh2Opt] = useState([]);
    const [eh2Opt, setEh2Opt] = useState([]);
    const [draft2, setDraft2] = useState(true);
    const [slot3Show, setSlot3Show] = useState(false);
    const [bh3, setBh3] = useState(null);
    const [eh3, setEh3] = useState(null);
    const [bh3Opt, setBh3Opt] = useState([]);
    const [eh3Opt, setEh3Opt] = useState([]);
    const [draft3, setDraft3] = useState(true);
    const [slot4Show, setSlot4Show] = useState(false);
    const [bh4, setBh4] = useState(null);
    const [eh4, setEh4] = useState(null);
    const [bh4Opt, setBh4Opt] = useState([]);
    const [eh4Opt, setEh4Opt] = useState([]);
    const [draft4, setDraft4] = useState(true);
    const [slot5Show, setSlot5Show] = useState(false);
    const [bh5, setBh5] = useState(null);
    const [eh5, setEh5] = useState(null);
    const [bh5Opt, setBh5Opt] = useState([]);
    const [eh5Opt, setEh5Opt] = useState([]);
    const [draft5, setDraft5] = useState(true);
    const [day, setDay] = useState(0);
    const { tokens } = useTheme();
    // const { data: ats } = useMyFetch('attendance', false);
    const [ats,setAts] = useState([]);
    const [memberImage, setMemberImage] = useState({});

    const updateFields = (newDay) => {
        setSlot1Show(true);
        setSlot2Show(false);
        setSlot3Show(false);
        setSlot4Show(false);
        setSlot5Show(false);
        setEh1("");
        setBh1("");
        setEh2("");
        setBh2("");
        setEh3("");
        setBh3("");
        setEh4("");
        setBh4("");
        setEh5("");
        setBh5("");
        setDraft1(true);
        setDraft2(true);
        setDraft3(true);
        setDraft4(true);
        setDraft5(true);
        setEh1Opt(ehs);
        setBh1Opt(bhs);
        var count = 0;
        // console.log("Doing the update")
        // console.log(schedule);
        for (var i = 0; i < schedule.length; i++) {
            if (schedule[i].day === days[newDay]) {
                if (count === 0) {
                    setBh1Opt(bhs.slice(0, schedule[i].ehindex + 1));
                    setEh1Opt(ehs.slice(schedule[i].bhindex));
                    setEh1(schedule[i].eh);
                    setBh1(schedule[i].bh);
                    setDraft1(false);
                    setSlot1Show(true);
                    setSlot2Show(true);
                    setBh2Opt(bhs);
                    setEh2Opt(ehs);
                    // console.log("Set slot 1 show..." + count + schedule[i].day + schedule[i].eh + schedule[i].bh)
                } else if (count === 1) {
                    setEh2Opt(ehs.slice(schedule[i].bhindex));
                    setBh2Opt(bhs.slice(eh1, schedule[i].ehindex + 1));
                    setEh2(schedule[i].eh);
                    setBh2(schedule[i].bh);
                    setDraft2(false);
                    setSlot3Show(true);
                    setBh3Opt(bhs);
                    setEh3Opt(ehs);
                    // console.log("Set slot 2 show..." + count + schedule[i].day + schedule[i].eh + schedule[i].bh)
                } else if (count === 2) {
                    setEh3Opt(ehs.slice(schedule[i].bhindex));
                    setBh3Opt(bhs.slice(eh2, schedule[i].ehindex + 1));
                    setEh3(schedule[i].eh);
                    setBh3(schedule[i].bh);
                    setDraft3(false);
                    setSlot4Show(true);
                    setBh4Opt(bhs);
                    setEh4Opt(ehs);
                } else if (count === 3) {
                    setEh4Opt(ehs.slice(schedule[i].bhindex));
                    setBh4Opt(bhs.slice(eh3, schedule[i].ehindex + 1));
                    setEh4(schedule[i].eh);
                    setBh4(schedule[i].bh);
                    setDraft4(false);
                    setSlot5Show(true);
                    setBh5Opt(bhs);
                    setEh5Opt(ehs);
                } else if (count === 4) {
                    setEh5Opt(ehs.slice(schedule[i].bhindex));
                    setBh5Opt(bhs.slice(eh4, schedule[i].ehindex + 1));
                    setEh5(schedule[i].eh);
                    setBh5(schedule[i].bh);
                    setDraft5(false);
                } else {
                }
                count++;

            }
            // console.log("set slot 1 to show!!")
            setSlot1Show(true);
        }
    }

    const resetDay = () => {
        setSlot5Show(false);
        setSlot4Show(false);
        setSlot3Show(false);
        setSlot2Show(false);
        setDraft1(true);
        setEh5("");
        setBh5("");
        setEh4("");
        setBh4("");
        setEh3("");
        setBh3("");
        setEh2("");
        setBh2("");
        setEh1("");
        setBh1("");
        setEh1Opt(ehs);
        setBh1Opt(bhs);
        
        // console.log("Doing the day update")
        var newStr = convertBackSchedule(schedule).replaceAll(" ", "").replaceAll("[", "").replaceAll("]", "");
        // console.log(newStr);
        var dayStr = "00000000000000";
        var strArr = newStr.split("");
        for (var i = 0; i < 14; i++) {
            strArr[(day * 14) + i] = dayStr[i];
        }
        newStr = "[ " + strArr.join(" ") + " ]";
        var item = {};
        item.email = member.email;
        item.schedule = newStr;
        updateSchedule(item);
        setScheduleString(newStr);
        setSchedule(createScheduleArray(newStr));
    }

    const updateDay = () => {
        // console.log("Doing the day update")
        var newStr = convertBackSchedule(schedule).replaceAll(" ", "").replaceAll("[", "").replaceAll("]", "");
        // console.log(newStr);
        var dayStr = "00000000000000";
        var dayStrArr = dayStr.split("");
        // console.log(dayStrArr);
        if (slot1Show) {
            // console.log("Slot1 was show " + bh1 + eh1)
            for (var j = bhs.indexOf(bh1); j <= ehs.indexOf(eh1); j++) {
                dayStrArr[j] = "1";
            }
        }
        if (slot2Show) {
            for ( j = bhs.indexOf(bh2); j <= ehs.indexOf(eh2); j++) {
                dayStrArr[j] = "1";
            }
        }
        if (slot3Show) {
            for ( j = bhs.indexOf(bh3); j <= ehs.indexOf(eh3); j++) {
                dayStrArr[j] = "1";
            }
        }
        if (slot4Show) {
            for ( j = bhs.indexOf(bh4); j <= ehs.indexOf(eh4); j++) {
                dayStrArr[j] = "1";
            }
        }
        if (slot5Show) {
            for ( j = bhs.indexOf(bh5); j <= ehs.indexOf(eh5); j++) {
                dayStrArr[j] = "1";
            }
        }
        dayStr = dayStrArr.join("");
        console.log(dayStr);

        var strArr = newStr.split("");
        for (var i = 0; i < 14; i++) {
            strArr[(day * 14) + i] = dayStr[i];
        }
        newStr = "[ " + strArr.join(" ") + " ]";
        console.log(newStr);
        var item = {};
        item.email = member.email;
        item.schedule = newStr;
        updateSchedule(item);
        setScheduleString(newStr);
        setSchedule(createScheduleArray(newStr));
    }

    useEffect(() => {
        (async function () {
            const tempEmail = await currentAuthenticatedUser();
            // console.log("tempemail: "+tempEmail);
            var tempMember = await getMember(tempEmail);
            if (tempMember !== undefined){
                const path = "members/"+tempMember.country+"/"+tempMember.pk_hash+".png";
                const imsResult = await list({prefix:path, options:{level: 'public'}});
                // console.log(imsResult);
                if(imsResult.items.length>0) {
                    setMemberImage(imsResult.items[0].key);
                    // console.log("Set the image")
                }
                setMember(await tempMember);
                var tempAttendance = await getAttendance(tempEmail);
                // console.log("attendance: ")
                // console.log(await tempAttendance);
                setAts(tempAttendance);
                
                // console.log(member.member_name);
                var sched = await getSchedule(tempEmail);
                setSchedule(sched);
                setScheduleString(convertBackSchedule(sched));
                var count = 0;
            for (var i = 0; i < sched.length; i++) {
                if (sched[i].day === days[day]) {
                    // console.log("Found the right day" + day + days[day] + sched[i].day);
                    if (count === 0) {
                        setBh1Opt(bhs.slice(0, sched[i].ehindex + 1));
                        setEh1Opt(ehs.slice(sched[i].bhindex));
                        setEh1(sched[i].eh);
                        setBh1(sched[i].bh);
                        setSlot1Show(true);
                        setSlot2Show(true);
                        setDraft1(false);
                        setEh2Opt(ehs);
                        setBh2Opt(bhs);
                    } else if (count === 1) {
                        setEh2Opt(ehs.slice(sched[i].bhindex));
                        setBh2Opt(bhs.slice(0, sched[i].ehindex + 1));
                        setEh2(sched[i].eh);
                        setBh2(sched[i].bh);
                        setSlot3Show(true);
                        setDraft2(false);
                        setEh3Opt(ehs);
                        setBh3Opt(bhs);
                        // console.log("Set slot 2 show..." + count + sched[i].day)
                    } else if (count === 2) {
                        setEh3Opt(ehs.slice(sched[i].bhindex));
                        setBh3Opt(bhs.slice(0, sched[i].ehindex + 1));
                        setEh3(sched[i].eh);
                        setBh3(sched[i].bh);
                        setSlot4Show(true);
                        setDraft3(false);
                        setEh4Opt(ehs);
                        setBh4Opt(bhs);
                    } else if (count === 3) {
                        setEh4Opt(ehs.slice(sched[i].bhindex));
                        setBh4Opt(bhs.slice(0, sched[i].ehindex + 1));
                        setEh4(sched[i].eh);
                        setBh4(sched[i].bh);
                        setSlot5Show(true);
                        setDraft4(false);
                        setEh5Opt(ehs);
                        setBh5Opt(bhs);
                    } else if (count === 4) {
                        setEh5Opt(ehs.slice(sched[i].bhindex));
                        setBh5Opt(bhs.slice(0, sched[i].ehindex + 1));
                        setEh5(sched[i].eh);
                        setBh5(sched[i].bh);
                        setDraft5(false);
                    } else {
                    }
                    count++;
                    }
                }
            } else {
                navigate('/NewMember');
            }
        })();
        // getMember(currentAuthenticatedUser());
    },[]);

    //Fetch the schedule
    // useEffect(() => {
    //     const abortCont = new AbortController();
    //     var pk = ''
    //     setTimeout(() => {
    //         getCurrentUser().then((user) => {
    //             // console.log('user email = ' + user.attributes.email);
    //             pk = 'member-' + user.attributes.email
    //             var result = get({apiName: 'membereventsapi', path: '/schedule/' + pk, options: {}}).then(
    //                 {
    //                 // console.log(JSON.parse(result.body));
    //             var sched = JSON.parse( result.body);
    //             setScheduleString(convertBackSchedule(sched));
    //             setSchedule(sched);
    //             var count = 0;
    //             for (var i = 0; i < sched.length; i++) {
    //                 if (sched[i].day === days[day]) {
    //                     // console.log("Found the right day" + day + days[day] + sched[i].day);
    //                     if (count === 0) {
    //                         setBh1Opt(bhs.slice(0, sched[i].ehindex + 1));
    //                         setEh1Opt(ehs.slice(sched[i].bhindex));
    //                         setEh1(sched[i].eh);
    //                         setBh1(sched[i].bh);
    //                         setSlot1Show(true);
    //                         setSlot2Show(true);
    //                         setDraft1(false);
    //                         setEh2Opt(ehs);
    //                         setBh2Opt(bhs);
    //                     } else if (count === 1) {
    //                         setEh2Opt(ehs.slice(sched[i].bhindex));
    //                         setBh2Opt(bhs.slice(0, sched[i].ehindex + 1));
    //                         setEh2(sched[i].eh);
    //                         setBh2(sched[i].bh);
    //                         setSlot3Show(true);
    //                         setDraft2(false);
    //                         setEh3Opt(ehs);
    //                         setBh3Opt(bhs);
    //                         // console.log("Set slot 2 show..." + count + sched[i].day)
    //                     } else if (count === 2) {
    //                         setEh3Opt(ehs.slice(sched[i].bhindex));
    //                         setBh3Opt(bhs.slice(0, sched[i].ehindex + 1));
    //                         setEh3(sched[i].eh);
    //                         setBh3(sched[i].bh);
    //                         setSlot4Show(true);
    //                         setDraft3(false);
    //                         setEh4Opt(ehs);
    //                         setBh4Opt(bhs);
    //                     } else if (count === 3) {
    //                         setEh4Opt(ehs.slice(sched[i].bhindex));
    //                         setBh4Opt(bhs.slice(0, sched[i].ehindex + 1));
    //                         setEh4(sched[i].eh);
    //                         setBh4(sched[i].bh);
    //                         setSlot5Show(true);
    //                         setDraft4(false);
    //                         setEh5Opt(ehs);
    //                         setBh5Opt(bhs);
    //                     } else if (count === 4) {
    //                         setEh5Opt(ehs.slice(sched[i].bhindex));
    //                         setBh5Opt(bhs.slice(0, sched[i].ehindex + 1));
    //                         setEh5(sched[i].eh);
    //                         setBh5(sched[i].bh);
    //                         setDraft5(false);
    //                     } else {
    //                     }
    //                     count++;
    //                     }
    //                 }
    //             }).catch(err => {
    //                 console.log(err);
    //                 console.log('Member - didnt get member');
    //                 // navigate('/NewMember')
    //             })
    //         })

    //     }, 1000);
    //     // abort the fetch
    //     return () => abortCont.abort();
    // }, [])


    return (
        <div with = "100%">
            <div>
                <Flex backgroundColor={tokens.colors.background.primary} width="100%">
                    {state.state.state === "desktop" && schedule &&
                        <DesktopLayout
                            display="block"
                            width="100%"

                            navBar=<Navbar />
                            mainContent=<FormHolder width="100%"
                                overrides={{
                                    "InnerContent": {
                                        height: (100 * 6 + 100)
                                    },
                                    "FormHolder": {
                                        height: (100 * 6 + 150)
                                    }
                                }}
                                innerContent=<EditScheduleWrapper 
                                    buttoncolor={settings.Theme.PrimaryColor}
                                    width = "100%"
                                    day={days[day]}
                                    nextDisabled={day === 6}
                                    previousDisabled={day === 0}
                                    nextHandler={() => {
                                        if (day === 6) return;
                                        var newDay = day + 1;
                                        setDay(newDay);
                                        updateFields(newDay);
                                    }}
                                    previousHandler={() => {
                                        if (day === 0) return;
                                        var newDay = day - 1;
                                        setDay(newDay);
                                        updateFields(newDay);
                                    }}
                                    overrides={{
                                        "InnerContent": {
                                            height: (100 * 6 + 100)
                                        },
                                        "EditScheduleWrapper": {
                                            height: (100 * 6 + 100)
                                        }
                                    }}
                                    innerContent=
                                    <div width="100%">
                                        {slot1Show && <ScheduleItem width="100%"
                                            buttoncolor={settings.Theme.PrimaryColor}
                                            background={draft1 ? tokens.colors.background.warning : "white"}
                                            updateHandler={() => {
                                                updateDay();
                                                setDraft1(false);
                                                setSlot2Show(true);
                                                setBh2Opt(bhs);
                                                setEh2Opt(ehs);
                                            }}
                                            bhChangeHandler={(change) => {
                                                setDraft1(true);
                                                setBh1(change.target.value);
                                            }}
                                            ehChangeHandler={(change) => {
                                                setDraft1(true);
                                                setEh1(change.target.value);
                                            }}
                                            updateText="Update"
                                            overrides={{
                                                "StartTimeField": {
                                                    value: bh1,
                                                    options: bh1Opt
                                                },
                                                "EndTimeField": {
                                                    value: eh1,
                                                    options: eh1Opt
                                                }
                                            }}
                                        />}

                                        {slot2Show && <ScheduleItem width="100%"
                                            buttoncolor={settings.Theme.PrimaryColor}
                                            background={draft2 ? tokens.colors.background.warning : "white"}
                                            updateHandler={() => {
                                                updateDay();
                                                setDraft2(false);
                                                setSlot3Show(true);
                                                setBh3Opt(bhs);
                                                setEh3Opt(ehs);
                                            }}
                                            bhChangeHandler={(change) => {
                                                setDraft2(true);
                                                setBh2(change.target.value);
                                            }}
                                            ehChangeHandler={(change) => {
                                                setDraft2(true);
                                                setEh2(change.target.value);
                                            }}
                                            overrides={{
                                                "StartTimeField": {
                                                    value: bh2,
                                                    options: bh2Opt
                                                },
                                                "EndTimeField": {
                                                    value: eh2,
                                                    options: eh2Opt
                                                }

                                            }}
                                        />}
                                        {slot3Show && <ScheduleItem
                                            background={draft3 ? tokens.colors.background.warning : "white"}
                                            buttoncolor={settings.Theme.PrimaryColor}
                                            updateHandler={() => {
                                                updateDay();
                                                setDraft3(false);
                                                setSlot4Show(true);
                                                setBh4Opt(bhs);
                                                setEh4Opt(ehs);
                                            }}
                                            bhChangeHandler={(change) => {
                                                setDraft3(true);
                                                setBh3(change.target.value);
                                            }}
                                            ehChangeHandler={(change) => {
                                                setDraft3(true);
                                                setEh3(change.target.value);
                                            }}
                                            overrides={{
                                                "StartTimeField": {
                                                    value: bh3,
                                                    options: bh3Opt,
                                                },
                                                "EndTimeField": {
                                                    value: eh3,
                                                    options: eh3Opt
                                                }

                                            }}
                                        />}
                                        {slot4Show && <ScheduleItem
                                            background={draft4 ? tokens.colors.background.warning : "white"}
                                            buttoncolor={settings.Theme.PrimaryColor}
                                            updateHandler={() => {
                                                updateDay();
                                                setDraft4(false);
                                                setSlot5Show(true);
                                                setBh5Opt(bhs);
                                                setEh5Opt(ehs);
                                            }}
                                            bhChangeHandler={(change) => {
                                                setDraft4(true);
                                                setBh4(change.target.value);
                                            }}
                                            ehChangeHandler={(change) => {
                                                setDraft4(true);
                                                setEh4(change.target.value);
                                            }}
                                            overrides={{
                                                "StartTimeField": {
                                                    value: bh4,
                                                    options: bh4Opt
                                                },
                                                "EndTimeField": {
                                                    value: eh4,
                                                    options: eh4Opt
                                                }

                                            }}
                                        />}
                                        {slot5Show && <ScheduleItem
                                            background={draft5 ? tokens.colors.background.warning : "white"}
                                            buttoncolor={settings.Theme.PrimaryColor}
                                            updateHandler={() => {
                                                updateDay();
                                                setDraft5(false);
                                            }}
                                            bhChangeHandler={(change) => {
                                                setDraft5(true);
                                                setBh5(change.target.value);
                                            }}
                                            ehChangeHandler={(change) => {
                                                setDraft5(true);
                                                setEh5(change.target.value);
                                            }}
                                            overrides={{
                                                "StartTimeField": {
                                                    value: bh5,
                                                    options: bh5Opt
                                                },
                                                "EndTimeField": {
                                                    value: eh5,
                                                    options: eh5Opt
                                                }

                                            }}
                                        />}
                                        {<Button
                                            width="unset"
                                            height="unset"
                                            shrink="0"
                                            size="small"
                                            isDisabled={false}
                                            variation="primary"
                                            children={"Reset"}
                                            backgroundColor={settings.Theme.PrimaryColor}
                                            onClick={() => {
                                                resetDay();
                                            }}
                                            ></Button>}
                                    </div>
                                />
                            />
                            leftBar={member && ats && scheduleString && <MyDetailsHeader imageFrame={<StorageImage alt="Member" imgKey={memberImage} accessLevel="guest" />} 
                                name={member.member_name}
                                email={member.email}
                                status={member.member_status === "initial_trial" ? "Free Trial" : (member.member_status === "basic" ? "Unsubscribed User" : (member.member_status === "paid" ? "Full Member" : (member.member_status === "stripe_trial"? "Subscribed Trial Period" : "")))}
                                stat1title="Available Hours"
                                stat2title="Unavailable Hours"
                                stat3title="Availability"
                                date={ats.length > 0 ? (new Date(ats[0].event.date_time.slice(0, 10))).toLocaleDateString() : ""}
                                time={ats.length > 0 ? ats[0].event.date_time.slice(11, 16) : ""}
                                pace={ats.length > 0 ? (Math.round(ats[0].event.pace * 10) / 10) + "kph" : ""}
                                stat1={(scheduleString.split("1").length - 1)}
                                stat2={(scheduleString.split("0").length - 1)}
                                stat3={((scheduleString.split("1").length - 1) /
                                    ((scheduleString.split("1").length + scheduleString.split("0").length - 1)))
                                    .toLocaleString(undefined, { style: 'percent', minimumFractionDigits: 0 })}
                            />
                            }
                        />
                    }
                    {state.state.state==="phone" && 
                    <MobileLayout width="100%"
                            mobileHeader=<MobileHeaderBar width="100%"
                                logoURL={logo}
                                burgerURL={MenuIcon}
                                navigateHomeURL={"/HomePage"}
                                burgerHandler={() => {
                                    setMenu(!menu);
                                    console.log("burger!")
                                }} />
                            firstContent=<Flex width="100%">
                                {!menu &&member && <EditScheduleWrapper
                                    buttoncolor={settings.Theme.PrimaryColor}
                                    day={days[day]}
                                    nextDisabled={day === 6}
                                    previousDisabled={day === 0}
                                    nextHandler={() => {
                                        if (day === 6) return;
                                        var newDay = day + 1;
                                        setDay(newDay);
                                        updateFields(newDay);
                                    }}
                                    previousHandler={() => {
                                        if (day === 0) return;
                                        var newDay = day - 1;
                                        setDay(newDay);
                                        updateFields(newDay);
                                    }}
                                    overrides={{
                                        "InnerContent": {
                                            height: (100 * 6 + 100)
                                        },
                                        "EditScheduleWrapper": {
                                            height: (100 * 6 + 100)
                                        }
                                    }}
                                    innerContent=
                                    <div>
                                        {slot1Show && <ScheduleItem width="100%"
                                            background={draft1 ? tokens.colors.background.warning : "white"}
                                            updateHandler={() => {
                                                updateDay();
                                                setDraft1(false);
                                                setSlot2Show(true);
                                                setBh2Opt(bhs);
                                                setEh2Opt(ehs);
                                            }}
                                            bhChangeHandler={(change) => {
                                                setDraft1(true);
                                                setBh1(change.target.value);
                                            }}
                                            ehChangeHandler={(change) => {
                                                setDraft1(true);
                                                setEh1(change.target.value);
                                            }}
                                            overrides={{
                                                "StartTimeField": {
                                                    value: bh1,
                                                    options: bh1Opt
                                                },
                                                "EndTimeField": {
                                                    value: eh1,
                                                    options: eh1Opt
                                                }

                                            }}
                                        />}

                                        {slot2Show && <ScheduleItem width="100%"
                                            background={draft2 ? tokens.colors.background.warning : "white"}
                                            updateHandler={() => {
                                                updateDay();
                                                setDraft2(false);
                                                setSlot3Show(true);
                                                setBh3Opt(bhs);
                                                setEh3Opt(ehs);
                                            }}
                                            bhChangeHandler={(change) => {
                                                setDraft2(true);
                                                setBh2(change.target.value);
                                            }}
                                            ehChangeHandler={(change) => {
                                                setDraft2(true);
                                                setEh2(change.target.value);
                                            }}
                                            overrides={{
                                                "StartTimeField": {
                                                    value: bh2,
                                                    options: bh2Opt
                                                },
                                                "EndTimeField": {
                                                    value: eh2,
                                                    options: eh2Opt
                                                }

                                            }}
                                        />}
                                        {slot3Show && <ScheduleItem
                                            background={draft3 ? tokens.colors.background.warning : "white"}
                                            updateHandler={() => {
                                                updateDay();
                                                setDraft3(false);
                                                setSlot4Show(true);
                                                setBh4Opt(bhs);
                                                setEh4Opt(ehs);
                                            }}
                                            bhChangeHandler={(change) => {
                                                setDraft3(true);
                                                setBh3(change.target.value);
                                            }}
                                            ehChangeHandler={(change) => {
                                                setDraft3(true);
                                                setEh3(change.target.value);
                                            }}
                                            overrides={{
                                                "StartTimeField": {
                                                    value: bh3,
                                                    options: bh3Opt,
                                                },
                                                "EndTimeField": {
                                                    value: eh3,
                                                    options: eh3Opt
                                                }

                                            }}
                                        />}
                                        {slot4Show && <ScheduleItem
                                            background={draft4 ? tokens.colors.background.warning : "white"}
                                            updateHandler={() => {
                                                updateDay();
                                                setDraft4(false);
                                                setSlot5Show(true);
                                                setBh5Opt(bhs);
                                                setEh5Opt(ehs);
                                            }}
                                            bhChangeHandler={(change) => {
                                                setDraft4(true);
                                                setBh4(change.target.value);
                                            }}
                                            ehChangeHandler={(change) => {
                                                setDraft4(true);
                                                setEh4(change.target.value);
                                            }}
                                            overrides={{
                                                "StartTimeField": {
                                                    value: bh4,
                                                    options: bh4Opt
                                                },
                                                "EndTimeField": {
                                                    value: eh4,
                                                    options: eh4Opt
                                                }

                                            }}
                                        />}
                                        {slot5Show && <ScheduleItem width="100%"
                                            background={draft5 ? tokens.colors.background.warning : "white"}
                                            updateHandler={() => {
                                                updateDay();
                                                setDraft5(false);
                                            }}
                                            bhChangeHandler={(change) => {
                                                setDraft5(true);
                                                setBh5(change.target.value);
                                            }}
                                            ehChangeHandler={(change) => {
                                                setDraft5(true);
                                                setEh5(change.target.value);
                                            }}
                                            overrides={{
                                                "StartTimeField": {
                                                    value: bh5,
                                                    options: bh5Opt
                                                },
                                                "EndTimeField": {
                                                    value: eh5,
                                                    options: eh5Opt
                                                }

                                            }}
                                        />}
                                        {<Button
                                            width="unset"
                                            height="unset"
                                            shrink="0"
                                            size="small"
                                            isDisabled={false}
                                            variation="primary"
                                            children={"Reset"}
                                            onClick={() => {
                                                resetDay();
                                            }}
                                            ></Button>}
                                    </div>
                                />}
                                {member && menu && <MobileSideBar width = "100%"
                                    name={member.member_name}
                                    logoURL={RDVLogo}
                                    logoutURL={LogoutIcon}
                                    runListURL={"/RunList"}
                                    myDetailsURL={"/MyDetails"}
                                    scheduleURL={"/Schedule"}
                                    subscriptionURL={"/Subscribe"}
                                    homeURL={"/HomePage"}
                                />
                                }
                                </Flex>
                                
                    />
                        
                    }
                </Flex>
            </div>
        </div>
    );
}

export default Schedule;