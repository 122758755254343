import { Flex, useTheme, Image, Text, TextField, Button, SelectField } from '@aws-amplify/ui-react';
import { getCurrentUser } from 'aws-amplify/auth';
import { useNavigate } from 'react-router-dom';
import DesktopLayout from '../ui-components/DesktopLayout.jsx';
import MobileLayout from '../ui-components/MobileLayout.jsx';
import MobileProgressBar from '../ui-components/MobileProgressBar.jsx';
import RDVLogo from '../specific/images/Logo.png';
import { useState, useEffect } from 'react';
import SubscriptionDetails from '../ui-components/SubscriptionDetails.jsx';
import settings from '../specific/settings.json';
import appstoreblack from '../resources/appstoreblack.svg';
import playstoreblack from '../resources/google-play-badge.png';
import ProgressBar from '../ui-components/ProgressBar.jsx';
import { useIntl } from 'react-intl';
import getMember from '../utils/getMember.js';
import { signUp, signOut, deleteUser } from "aws-amplify/auth"
import {
  EmailShareButton,
  FacebookShareButton,
  WhatsappShareButton,
  WhatsappIcon,
  FacebookIcon,
  EmailIcon
} from "react-share";

function ThankYou(state) {
  const navigate = useNavigate();
  const [member, setMember] = useState({});
  const { tokens } = useTheme();
  const [membershipLevel, setMembershipLevel] = useState("Basic");

  const [stage, setStage] = useState(6); //0: Not Started; 1: Details created; 2: Signed-up; 3:Confirmed; 4: Signed-in 4: Paid; 5: Schedule Provided;
  const intl = useIntl();
  const [currencySymbol, setCurrencySymbol] = useState(intl.locale === "en" ? "£" : intl.locale === "be" ? "€" : "$")

  useEffect(() => {
    (async function () {
      //First see if there's an authenticated user
      const tempEmail = await currentAuthenticatedUser();
      console.log("Temp Email: " + tempEmail);
      
      if (tempEmail == null) {
        //THis is bad
      } else {
        getMember(tempEmail).then((tempMember) => {
          if(typeof tempMember === undefined){
            // deleteUser();
            console.log("Type of user was undefined");
            signOut();
            window.location.replace(settings.Domain+"NewMember");
            return;
          }
          console.log(tempMember);
          setMember(tempMember);
        })
      }
      
    })();
  }, []);


  const mainContent = (
                <Flex direction={'column'} marginLeft={state.state.state === "desktop"? 20: 0} width={"100%"} backgroundColor={"#ffffff"} padding={20}>
                  <Text>Thanks for signing up to Rundezvous!</Text>

                  <Text>You can now get the app for your mobile device:</Text>
                  <div marginBottom={"20px"}>
                    <Image src={appstoreblack} width={100} marginRight={20} onClick={() => window.open(settings.URLs.AppStore, "_blank")} />
                    <Image src={playstoreblack} width={112} paddingTop={20} onClick={() => window.open(settings.URLs.PlayStore, "_blank")} />
                  </div>
                  <Flex direction={'column'} width={"100%"} padding={20}>
                    <Text>There are currently only a few members in your area, refer your friends so we can find times for you to run together using your personal referral link and we'll give you a free month of full membership every time someone joins!</Text>
                    <Text> {settings.Domain+"referral/"+member.my_referral_code}</Text>
                    <Flex direction={'column'} alignItems={'center'}>
                    <Flex>
                        <WhatsappShareButton title={"Join me on Rundezvous!"} children={<WhatsappIcon/>} url={settings.Domain+"referral/"+member.my_referral_code}/>
                        <FacebookShareButton title={"Join me on Rundezvous!"} hashtag={"#Rundezvous"} children={<FacebookIcon/>} url={settings.Domain+"referral/"+member.my_referral_code}/>
                        <EmailShareButton subject={"Join me on Rundezvous!"} body={"Come and join me on Rundezvous and we can run together!"} separator=' : ' children={<EmailIcon/>} url={"www.rundezvous.io/referral/"+member.my_referral_code}/>
                        {/* <FacebookMessengerShareButton title={"Join me on Rundezvous!"} hashtag={"#Rundezvous"} children={<FacebookMessengerIcon/>} url={"www.rundezvous.io/referral/"+member.my_referral_code}/> */}
                      </Flex>
                    </Flex>
                  </Flex>
                  <Flex paddingLeft={20} paddingBottom={20} paddingRight={20}>
                    <Button width={"100%"} onClick={() => navigate('/RunList')}>Home</Button>
                  </Flex>
                </Flex>

  )

  const secondaryContent = (
    member &&
      <>
        
        {membershipLevel == "Full" ?

        <SubscriptionDetails
          overrides={
            {"Frame 575":{height: "0px"},
              "Frame 580":{height: "0px"}
            }}
          width={"100%"}
          logosrc={RDVLogo}
          priceStr={currencySymbol + "0 (for now)"}
          subscribestr={"Submit"}
          titlestr={"Rundezvous"}
          subtitlestr={"One run per week."}
          priceslot={""}
          buttoncolor={settings.Theme.PrimaryColor}
          termsurl={settings.Domain + "terms"}
          subsubtitlestr={"hello2"}
          firstqstr={"What does Rundezvous do?"}
          firstastr={"Once each week our algorithm will attempt to match you with social running events."}
          firsta2str={"We will do our best to match you with at least one run every week."}
          secondqstr={"Free for early adopters?"}
          secondastr={"If you sign-up now you will stay a free Basic Member forever. Sign-up now as this may not be able to stay free forever!"}
          seconda2str={"Our algorithm works best once there is good uptake in your area, so initially there may not be enough people to create the best events for you. Refer your friends and get free months of free membership!"}
          appstr={"Is there an app?"}
          appastr={"Yes, Rundezvous is available on iOS, Android and in your browser."}
          showterms={true}
          appArea=
            <div>
              <Image src={appstoreblack} width={100} marginRight={20}  />
              <Image src={playstoreblack} width={112} paddingTop={20}  />
            </div>
        />
          :
          <SubscriptionDetails
            overrides={
              {"Frame 575":{height: "0px"},
                "Frame 580":{height: "0px"}
              }}
            width={"100%"}
            logosrc={RDVLogo}
            priceStr={currencySymbol + "0 (for now)"}
            subscribestr={"Submit"}
            titlestr={"Rundezvous"}
            subtitlestr={"A social run matching service"}
            priceslot={""}
            buttoncolor={settings.Theme.PrimaryColor}
            termsurl={settings.Domain + "terms"}
            subsubtitlestr={""}
            firstqstr={"What does Rundezvous do?"}
            firstastr={"Each week we look for other runners in your area who might free slots in their schedule at the same time as you. We will then send you personalised invites to social running events where you can exercise and meet new people!"}
            firsta2str={"We will do our best to match you with at least one run every week."}
            secondqstr={"Is there an app?"}
            secondastr={"Yes, in adition to emailing you events, you can view your runs in the Rundezvous app, and share photos with others."}
            seconda2str={""}
            appstr={""}
            appastr={""}
            showterms={true}
            appArea=
            <div>
              <Image src={appstoreblack} width={100} marginRight={20}/>
              <Image src={playstoreblack} width={112} paddingTop={20}/>
            </div>
          />
        }
      </>
  )

  return (
        <Flex width={"100%"} backgroundColor={tokens.colors.background.primary}>
          {(state.state.state === "desktop" ?
            (
              <DesktopLayout
                display="block"
                width={"100%"}
                navBar=<ProgressBar width={"100%"} oneactive={stage === 0} onecomplete={stage > 0} onetocome={stage < 0}
                  twoactive={stage === 2} twocomplete={stage > 2} twotocome={stage < 2}
                  threeactive={stage === 4} threecomplete={stage > 4} threetocome={stage < 4}
                  fouractive={stage === 5} fourcomplete={stage > 5} fourtocome={stage < 5} />
                mainContent={mainContent}
                leftBar={secondaryContent}
              />)
            :
            <MobileLayout width="100%"
              mobileHeader=<MobileProgressBar width="100%"
              oneactive={stage === 0} onecomplete={stage > 0} onetocome={stage < 0}
              twoactive={stage === 2} twocomplete={stage > 2} twotocome={stage < 2}
              threeactive={stage === 4} threecomplete={stage > 4} threetocome={stage < 4}
              fouractive={stage === 5} fourcomplete={stage > 5} fourtocome={stage < 5}
                />
              firstContent=<div>
                {mainContent}
                {secondaryContent}
              </div>
            />
          )}
        </Flex>
  );
}

async function currentAuthenticatedUser() {
  try {
    const { username, userId, signInDetails } = await getCurrentUser();
    // console.log(`The username: ${username}`);
    // console.log(`The userId: ${userId}`);
    // console.log(`The signInDetails: ${signInDetails.loginId}`);
    // console.log(signInDetails);
    return signInDetails.loginId;
  } catch (err) {
    console.log(err);
  }
}


export default ThankYou;